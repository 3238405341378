import React from 'react'
import { Link } from 'react-router-dom';
import useFetchAllProducts from '../hooks/useFetchAllProducts';

const NewProducts = () => {
  const productPhotos = useFetchAllProducts();
  const { allProducts } = productPhotos;
  return (
    <div className="section-3-container">
      <h3>New Products</h3>
      <div className="container mx-auto section-3-images " >
        {
          allProducts.slice(0,12).map(product =>
            <Link to="/product/1" className="image-links" state={{ product: product, type: product.type }}>
              <img src={product.thumbnailUrl} alt="product-img" data-aos="flip-left" />
            </Link>
          )
        }


      </div>
    </div>
  )
}

export default NewProducts