import React, { useEffect } from 'react';

const GAScript: React.FC = () => {
  useEffect(() => {
    // Add the main gtag.js script
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-HHR736C8K4";
    document.head.appendChild(script1);

    // Add the Google Analytics configuration script
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-HHR736C8K4');
    `;
    document.head.appendChild(script2);
  }, []);

  return null;
}

export default GAScript;
